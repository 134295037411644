import React from "react";

import GamesIcon from "../assets/images/footer-icon/foot-games.svg";
import GamesIconActive from "../assets/images/footer-icon/foot-games-ac.svg";
import SportsIcon from "../assets/images/footer-icon/foot-sports.svg";
import SportsIconActive from "../assets/images/footer-icon/foot-sports-ac.svg";
import HomeIcon from "../assets/images/Profileicon.png";
import CashierIcon from "../assets/images/footer-icon/foot-cashier.svg";
import CashierIconActive from "../assets/images/footer-icon/foot-cashier-ac.svg";
import ProfileIcon from "../assets/images/footer-icon/foot-profile.svg";
import ProfileIconActive from "../assets/images/footer-icon/foot-profile-ac.svg";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { APP_CONST } from "../config/const";
import LoginModal from "../views/components/authModals/LoginModal";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import ForgotPasswords from "../views/components/authModals/ForgotPassword";
import ResetPassword from "../views/components/authModals/ResetPassword";

export default function BottomNav() {
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);

  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
    forgotpassword: false,
    resetpassword: false,
  });

  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };

  const footerPages = [
    "home",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "deposit",
    "cashier",
    "support",
    "casino",
    "/",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <>
      <footer className="footer d-lg-none">
        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a
                className={activePage === "casino" ? "active" : ""}
                onClick={() => {
                  isAuth
                    ? (window.location.href = "/casino")
                    : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                }}
              >
                <img
                  src={GamesIcon}
                  alt="Foot Nav Icon"
                  className="no_active"
                />
                <img
                  src={GamesIconActive}
                  alt="Foot Nav Icon"
                  className="active"
                />
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "support" ? "active" : ""}
                onClick={() => {
                  isAuth
                    ? (window.location.href = "/sportsbook/Inplay")
                    : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                }}
              >
                <img
                  src={SportsIcon}
                  alt="Foot Nav Icon"
                  className="no_active"
                />
                <img
                  src={SportsIconActive}
                  alt="Foot Nav Icon"
                  className="active"
                />
                <span>Sports</span>
              </a>
            </li>
            <li className="home">
              <a
                className="active"
                onClick={() => {
                  isAuth
                    ? (window.location.href = "/sports")
                    : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                }}
              >
                <img src={HomeIcon} alt="Foot Nav Icon" />
                {/* <span>Home</span> */}
              </a>
            </li>
            <li>
              <a
                className={activePage === "cashier" ? "active" : ""}
                onClick={() => {
                  isAuth
                    ? (window.location.href = "/cashier")
                    : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                }}
              >
                <img
                  src={CashierIcon}
                  alt="Foot Nav Icon"
                  className="no_active"
                />
                <img
                  src={CashierIconActive}
                  alt="Foot Nav Icon"
                  className="active"
                />
                <span>Cashier</span>
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img
                  src={ProfileIcon}
                  alt="Foot Nav Icon"
                  className="no_active"
                />
                <img
                  src={ProfileIconActive}
                  alt="Foot Nav Icon"
                  className="active"
                />
                <span>24/7 Support</span>
              </a>
            </li>
          </ul>
        </div>
      </footer>

      <LoginModal
        show={showAuthModals.login}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
        handleModalShow={handleModalShow}
      />
      {showAuthModals.mobileNumber && (
        <MobileNumberModal
          show={showAuthModals.mobileNumber}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.verifyNumber && (
        <MobileNumberVerifyModal
          show={showAuthModals.verifyNumber}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.register && (
        <RegisterModal
          show={showAuthModals.register}
          handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
          handleModalShow={handleModalShow}
        />
      )}

      <ForgotPasswords
        show={showAuthModals.forgotpassword}
        handleClose={() =>
          handleModalClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD)
        }
        handleModalShow={handleModalShow}
      />
      <ResetPassword
        show={showAuthModals.resetpassword}
        handleClose={() =>
          handleModalClose(APP_CONST.AUTH_MODAL.RESET_PASSWORD)
        }
        handleModalShow={handleModalShow}
      />
    </>
  );
}
