import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { logoutUser } from "../redux/auth/actions";

import HomeIcon from "../assets/images/games-icon/home-icon.png";
import CasinoIcon from "../assets/images/games-icon/casino-icon.png";
import InplayIcon from "../assets/images/games-icon/inplay-icon.png";
import aviator from "../assets/images/games-icon/aviator.png";

import CricketIcon from "../assets/images/games-icon/cricket-icon.png";
import SoccerIcon from "../assets/images/games-icon/soccer-icon.png";
import TennisIcon from "../assets/images/games-icon/tennis-icon.png";
import horseracingIcon from "../assets/images/games-icon/horseracing-icon.png";
import EsportsIcon from "../assets/images/games-icon/esports-icon.png";
import SnookerIcon from "../assets/images/games-icon/snooker-icon.png";
import GreyhoundIcon from "../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "../assets/images/games-icon/politics-icon.png";
import SpaceIcon from "../assets/images/games-icon/aviator.png";
import SportsbookIcon from "../assets/images/games-icon/sportsbook-icon.png";

import WalletIcon from "../assets/images/WalletIcon.png";
import UserIcon from "../assets/images/user-icon.png";
import BetIcon from "../assets/images/BetIcon.png";
import AcStatement from "../assets/images/AcStatement.png";
import LogoutIconImg from "../assets/images/logout.png";
import BonusImg from "../assets/images/bonus.png";
import refferal from "../assets/images/refferal.png";

const MobLeftbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sportsData } = useSelector((state) => state.sports);
  const { isAuth } = useSelector((state) => state.auth);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const { user, balance, wallet } = useSelector((state) => state.auth);

  return (
    <div className="mobleftbarPage">
      <div className="leftbarSec">
        <ul>
          <li className="games_link">
            {/* <h6>Quick Links</h6> */}
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports");
                  }}
                >
                  <img src={HomeIcon} alt="Home Icon" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/spribe/aviator");
                  }}
                >
                  <img src={aviator} alt="Inplay Icon" />
                  <span>Aviator</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/betby", { state: { selectedTab: "1" } });
                  }}
                >
                  <img src={SportsbookIcon} alt="Inplay Icon" />
                  <span>Sportsbook</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Inplay", {
                      state: { selectedTab: "1" },
                    });
                  }}
                >
                  <img src={InplayIcon} alt="Inplay Icon" />
                  <span>In-play</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/worldcasino");
                  }}
                >
                  <img src={CasinoIcon} alt="Casino Icon" />
                  <span>World Casino</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Cricket", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={CricketIcon} alt="Cricket Icon" />
                  <span>Cricket</span>
                  <div class="sport-event-count badge">
                    {sportsData?.[0]?.values.length}
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Soccer", {
                      state: { selectedTab: "3" },
                    });
                  }}
                >
                  <img src={SoccerIcon} alt="Soccer Icon" />
                  <span>Soccer</span>
                  <div class="sport-event-count badge">
                    {sportsData?.[1]?.values.length}
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Tennis", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={TennisIcon} alt=" Icon" />
                  <span>Tennis</span>
                  <div class="sport-event-count badge">
                    {" "}
                    {sportsData?.[2]?.values.length}
                  </div>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Horse Racing">
                  <img src={horseracingIcon} alt="horseracing Icon" />
                  <span>Horse Racing</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Esports">
                  <img src={EsportsIcon} alt="Esports Icon" />
                  <span>Esports</span>
                </div>
              </li>
              <li>
                {/* <div href="/betby"> */}
                <div href="/sportsbook/Snooker">
                  <img src={SnookerIcon} alt="Snooker Icon" />
                  <span>Snooker</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Greyhound Racing">
                  <img src={GreyhoundIcon} alt="Greyhound Racing Icon" />
                  <span>Greyhound Racing</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Politics">
                  <img src={PoliticsIcon} alt="Politics Icon" />
                  <span>Politics</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/SPACE">
                  <img src={SpaceIcon} alt="Politics Icon" />
                  <span>SPACE</span>
                </div>
              </li>
            </ul>
          </li>

          <li className="games_link d-none">
            <h6>My Account</h6>
            <ul>
              <li>
                <div>
                  <a href="/mybets">
                    <img src={BetIcon} alt="my bet" />
                    <span>My Bets</span>
                  </a>
                </div>
              </li>

              <li>
                <div>
                  <a href="/referral" className="img_size">
                    <img src={refferal} />

                    <span className="bonus-space">Referral</span>
                  </a>
                </div>
              </li>
              <li>
                <div>
                  <a href="/cashier">
                    <img src={AcStatement} alt="account statement" />
                    <span>Account Statement</span>
                  </a>
                </div>
              </li>
              {isAuth && (
                <li onClick={() => dispatch(logoutUser())}>
                  <div>
                    <a href="/">
                      {/* <LogoutIcon /> */}
                      <img src={LogoutIconImg} />
                      <span>Sign Out</span>
                    </a>
                  </div>
                </li>
              )}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobLeftbar;
