import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HomeIcon from "./../../assets/images/games-icon/home-icon.png";
import CasinoIcon from "./../../assets/images/games-icon/casino-icon.png";
import SportsbookIcon from "./../../assets/images/games-icon/sportsbook-icon.png";
import InplayIcon from "./../../assets/images/games-icon/inplay-icon.png";
import CricketIcon from "./../../assets/images/games-icon/cricket-icon.png";
import SoccerIcon from "./../../assets/images/games-icon/soccer-icon.png";
import TennisIcon from "./../../assets/images/games-icon/tennis-icon.png";
import horseracingIcon from "./../../assets/images/games-icon/horseracing-icon.png";
import EsportsIcon from "./../../assets/images/games-icon/esports-icon.png";
import SnookerIcon from "./../../assets/images/games-icon/snooker-icon.png";
import GreyhoundIcon from "./../../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "./../../assets/images/games-icon/politics-icon.png";
import SpaceIcon from "./../../assets/images/games-icon/aviator.png";
import ModalWrapper from "../../containers/ModalWrapper";

const LeftBarSports = (isAuth) => {
  const navigate = useNavigate();
  const { isAuth: localAuth } = useSelector((state) => state.auth);
  console.log(localAuth);
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const [showAuthModals, setShowAuthModals] = useState(false);
  useEffect(() => {}, [showAuthModals]);
  const manageRedirection = (redirectUrl) => {
    console.log("checking");
    console.log(isAuth);
    console.log("First state authmodal", showAuthModals);
    console.log("localAuth", localAuth);
    if (localAuth) {
      navigate(redirectUrl);
    } else {
      console.log("clickkkkk");
      console.log("Before state update authmodal", showAuthModals);
      // setShowAuthModals(true)
      setShowAuthModals((prev) => !prev);
      console.log("After update authmodal state", showAuthModals);
    }
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  return (
    <>
      <div className="leftbarSec d-none d-xl-block">
        <ul>
          <li className="games_link">
            {/* <h6>Quick Links</h6> */}
            <ul>
              <li>
                <div
                  href="javascript:void(0)"
                  onClick={() => manageRedirection("/sports")}
                >
                  <img src={HomeIcon} alt="Home Icon" />
                  <span>Home</span>
                </div>
              </li>
              <li onClick={() => manageRedirection("/betby")}>
                <div>
                  <img src={SportsbookIcon} alt="Inplay Icon" />
                  <span>Sportsbook</span>
                </div>
              </li>
              <li onClick={() => manageRedirection("/sportsbook/Inplay")}>
                <div>
                  <img src={InplayIcon} alt="Inplay Icon" />
                  <span>In-play</span>
                </div>
              </li>
              <li>
                <div onClick={() => manageRedirection("/casino/worldcasino")}>
                  <img src={CasinoIcon} alt="Casino Icon" />
                  <span>World Casino</span>
                </div>
              </li>
              <li>
                <div onClick={() => manageRedirection("/sportsbook/Cricket")}>
                  <img src={CricketIcon} alt="Cricket Icon" />
                  <span>Cricket</span>
                  <div className="sport-event-count badge">
                    {sportsData?.[0]?.values.length}
                  </div>
                </div>
              </li>
              <li>
                <div onClick={() => manageRedirection("/sportsbook/Soccer")}>
                  <img src={SoccerIcon} alt="Soccer Icon" />
                  <span>Soccer</span>
                  <div className="sport-event-count badge">
                    {" "}
                    {sportsData?.[1]?.values.length}
                  </div>
                </div>
              </li>
              <li>
                <div onClick={() => manageRedirection("/sportsbook/Tennis")}>
                  <img src={TennisIcon} alt=" Icon" />
                  <span>Tennis</span>
                  <div className="sport-event-count badge">
                    {" "}
                    {sportsData?.[2]?.values.length}
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => manageRedirection("/sportsbook/Horse Racing")}
                >
                  <img src={horseracingIcon} alt="horseracing Icon" />
                  <span>Horse Racing</span>
                </div>
              </li>
              <li>
                <div onClick={() => manageRedirection("/sportsbook/Esports")}>
                  <img src={EsportsIcon} alt="Esports Icon" />
                  <span>Esports</span>
                </div>
              </li>
              <li>
                <div onClick={() => manageRedirection("/sportsbook/Snooker")}>
                  <img src={SnookerIcon} alt="Snooker Icon" />
                  <span>Snooker</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() =>
                    manageRedirection("/sportsbook/Greyhound Racing")
                  }
                >
                  <img src={GreyhoundIcon} alt="Greyhound Racing Icon" />
                  <span>Greyhound Racing</span>
                </div>
              </li>
              <li>
                <div onClick={() => manageRedirection("/sportsbook/Politics")}>
                  <img src={PoliticsIcon} alt="Politics Icon" />
                  <span>Politics</span>
                </div>
              </li>
              <li>
                <div onClick={() => manageRedirection("/sportsbook/SPACE")}>
                  <img src={SpaceIcon} alt="Politics Icon" />
                  <span>SPACE</span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      {showAuthModals && (
        <ModalWrapper defaultShow={"login"} handleClose={setShowAuthModals} />
      )}
    </>
  );
};

export default LeftBarSports;
