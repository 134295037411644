import React from "react";
import BackIcon from "../../../../assets/svg/BackIcon";
import { MdInfo, MdEmail } from "react-icons/md";
import { CgPhone } from "react-icons/cg";
import { RiWhatsappFill } from "react-icons/ri";
import { RiTelegramFill } from "react-icons/ri";
// import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

import HomeContact from "../../../../assets/images/home-cont-icon.svg";
import PhoneContact from "../../../../assets/images/phone-cont-icon.svg";
import EmailContact from "../../../../assets/images/email-cont-icon.svg";
import ContactImg from "../../../../assets/images/3D-illustration/contact-us-3.png";
import Whatapps from "../../../../assets/images/icons/whatsapp.png";
import Phone from "../../../../assets/images/icons/phone-grn.png";
import Telegram from "../../../../assets/images/icons/telegram-grn.png";
import { useSelector } from "react-redux";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";

const ContactUsBefore = () => {
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>
      <main className="main contact_sec" id="payment_page">
        <div className=" container">
          <div className="d-flex justify-content-between align-items-center HeadingSection mt-4">
            <h2 className="sectionTitle mb-0">Contact</h2>
            <a className="back-link" href="/">
              Back <BackIcon />
            </a>
          </div>

          <Row className="align-items-center justify-content-center">
            <Col lg={10} xl={8}>
              <div className="contact_info">
                <Row className="align-items-center">
                  <Col md={6}>
                    <h3 className="heading mb-4">Get In Touch With Us</h3>
                    <ul>
                      <li>
                        <a href={`tel://${appDetails?.PHONE}`}>
                          <div className="img_box">
                            <img src={Phone} alt="Phone Icon" />
                          </div>
                          <div>
                            <h5>Phone</h5>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <div className="img_box">
                            <img src={Whatapps} alt="Phone Icon" />
                          </div>
                          <div>
                            <h5>Whats App</h5>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "javascript:void(0)"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <div className="img_box">
                            <img src={Telegram} alt="Phone Icon" />
                          </div>
                          <div>
                            <h5>Telegram</h5>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col
                    md={6}
                    className="d-none d-md-flex justify-content-center"
                  >
                    <div className="contact_img">
                      <img src={ContactImg} alt="Contact Image" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </main>

      <div>{isAuth ? <Footer /> : <BeforeLoginFooter />}</div>
    </div>
  );
};

export default ContactUsBefore;
