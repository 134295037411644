import React from "react";
import Header from "../../../../containers/Header";

const BeforeExchange = () => {
  return (
    <div>
      <Header />
      <main className="main">
        <iframe
          src="https://design.powerplay247.com/auth"
          style={{ width: "100%", height: "100vh" }}
        />
      </main>
    </div>
  );
};

export default BeforeExchange;
